<template>
  <div class="news-page page">
    <div class="news-page__header">
      <div class="container-padding">
        <div class="news-page__inner">
          <h2>Новости</h2>
          <NewsInfoComponent v-if="newsFirst && newsFirst.length" :news="newsFirst[0]" />
        </div>
      </div>
    </div>
    <div class="news-page__bottom">
      <div class="container-padding">
        <div v-if="news && news.length" class="news-page__content">
          <NewsCardComponent v-for="(news, i) in news" :key="i" :news="news" />
        </div>
        <p v-else>В данный момент информация отсутствует</p>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCardComponent from "components/NewsCardComponent.vue";
import NewsInfoComponent from "../components/NewsInfoComponent.vue";
import NEWS_PAGE from "@/graphql/pages/news_page.graphql";

export default {
  name: "NewsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: NEWS_PAGE,
        variables: {},
      })
      .then(({ data }) => {
        store.state.news_page.news = data.news;
      });
  },
  computed: {
    newsFirst() {
      return this.$store.state.news_page.news.slice(0, 1) || [];
    },
    news() {
      return this.$store.state.news_page.news.slice(1) || [];
    },
  },
  metaInfo: {
    title: "Новости",
  },
  components: {
    NewsInfoComponent,
    NewsCardComponent,
  },
};
</script>

<style lang="stylus">
.news-page {

  &__header {
    padding 126px 0 56px 0
    background var(--gray-light)
  }

  &__inner {
    display flex
    flex-direction column
    gap 50px
  }

  &__bottom {
    padding 50px 0 0 0
  }

  &__content {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 50px
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(650px) {
      grid-template-columns 1fr
    }
  }
}
</style>
